<template>
  <div>
    <ul class="aside-tools" >
      <li class="top" v-show="scrollTop > 0">
        <div class="item" @click="goTop" >
          <img src="@/assets/image/Home/top.png" alt="">
          <div class="mt-10">回到顶部</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  components:{
  },
  data() {
    return {
      scrollTop:0
    }
  },
  computed: {
  },
  watch: {

  },
  created() {
  },
  methods: {
    /**
     * 监听滚动条
     */
    scrollToTop() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    },
    /**
     * 回到顶部
     */
    goTop(){
      cancelAnimationFrame(this.timer)
      const self = this
      self.timer = requestAnimationFrame(function fn () {
        const oTop = document.body.scrollTop || document.documentElement.scrollTop
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 150
          self.timer = requestAnimationFrame(fn)
        } else {
          cancelAnimationFrame(self.timer)
        }
      })
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop)
  },
}
</script>
<style lang="less">
.popoverAPP{
  .popover_header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 0 13px;
    .app_text{
      .appName{
        font-size: 14px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
      }
      .apptitle{
        font-size: 12px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 100;
        color: #1F1F1F;
        line-height: 17px;
      }
    }
  }
  .popover_body{
    display: flex;
    flex-direction: column;
    align-items: center;
    #qrCode{
      width: 120px;
      height: 120px;
      margin: 0 0 12px;
    }
  }
  .popover_footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #656565;
    line-height: 20px;
    .tips{
      font-size: 10px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #AFAFAF;
      line-height: 14px;
    }
  }
}
.popover_afterSale{
  .popover_header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 0 13px;
    .app_text{
      display: flex;
      flex-direction: column;
      align-items: center;
      .appName{
        font-size: 14px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
      }
      .apptitle{
        font-size: 12px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 100;
        color: #1F1F1F;
        line-height: 17px;
      }
    }
  }
  .popover_body{
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrCode{
      width: 120px;
      height: 120px;
      margin: 0 0 12px;
    }
  }
  .popover_footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #656565;
    line-height: 20px;
    .tips{
      font-size: 10px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #AFAFAF;
      line-height: 14px;
    }
  }
}
</style>
<style scoped lang="less">
.aside-tools {
  position: fixed;
  right: 30px;
  top: 50%;
  z-index: 1000;
  transition: right 0.2s ease-in-out 0s;
  width: 90px;
  //height: 366px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  li{
    position: relative;
    text-align: center;
    cursor: pointer;
    background: #fff;
    z-index: 1;
    padding:  0 0 29px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .item {
      color: #5B5B5B;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .item_img{
        height: 32px;
        width: 30px;
      }
      .mt-10{
        padding: 2px 0 0 0;
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #5B5B5B;
      }
    }
  }


  .top{
    padding: 24px 0 20px 0;
    border-radius: 0 0 10px 10px;
    width: 60px;
    margin: 0 16px;
    .item{
      div{
        color: #000000;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
      }
    }
  }
  li:hover{
    .item{
      div{
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #3D59BE;
      }
    }
  }

  .open-btn {
    position: absolute;
    top: 35%;
    left: -44px;
    height: 116px;
    width: 104px;
    cursor: pointer;
    //background: url("../../assets/image/aside-tools/aside_tools.png") no-repeat left;
    background-size:cover;
    img{
      position: absolute;
      left: 0;
      top: 36px;
      width: 36px;
      height: 44px;
      object-fit: cover;
    }
  }
  .close-btn{
    position: absolute;
    top: 35%;
    left: -80px;
    height: 116px;
    width: 104px;
    cursor: pointer;
    //background: url("../../assets/image/aside-tools/aside_tools.png") no-repeat left;
    background-size:cover;
    img{
      position: absolute;
      left: 165px;
      top: 36px;
      width: 36px;
      height: 44px;
      object-fit: cover;
    }
  }
}
.close {
  right: -100px;
}

</style>

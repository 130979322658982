/**
 * api接口统一管理
 */
import {get, post} from './http'

//全部游戏
export const apiGameList = p => get('select/game/list', p);
export const apiGameClass = p => get('select/game/class', p);
//游戏筛选列表 属性查询
export const apiProductCategory = p => get('select/product/category', p); //游戏账号
//游戏筛选列表 二级属性查询
export const apiProductPcate = p => get('select/product/pcate', p); //游戏账号
//游戏筛选列表 游戏账号列表
export const apiProductList = p => get('select/product/list', p);
//帐号详情
export const apiProductInfo = p => get('select/product/info', p);
//账号详情 收藏
export const apiCollectSet = p => post('select/collect/set', p);

//发起降价请求点击量
export const apiBargainClcik = p => post('select/product/click', p);

//配置
export const apiChannelConfig = p => get('select/channelConfig', p);







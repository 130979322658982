import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import Router from 'vue-router'
import md5 from 'js-md5'
import moment from 'moment/moment'
// 在main.js或需要使用的地方引入并建立连接


// 导入全局样式
import './assets/css/index.css'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// import './util/flexible'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.config.productionTip = false
Vue.use(Element)

Vue.filter('moment', function (value, formatString) {
  formatString = formatString || 'YYYY-MM-DD';
  return moment(value * 1000).format(formatString);
});
Vue.filter('moments', function (value, formatString) {
  formatString = formatString || 'YYYY-MM-DD HH:mm:ss';
  return moment(value * 1000).format(formatString);
});

Vue.prototype.$formatDate = (time, format) => {
  if(!time) return ''
  format = format || 'YYYY-MM-DD HH:mm:ss';
  return moment(time * 1000).format(format);
}

Vue.prototype.$axios = axios
Vue.prototype.$md5 = md5

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id='layout' :style="style">
    <router-view/>
    <RightTools/>
    <Footer></Footer>
  </div>
</template>
<script>
import RightTools from '@/components/RightTools/index'
import Footer from '@/components/footer'
import {apiChannelConfig} from "@/request/API";

export default {
  name: '',
  props: {},
  components: {
    RightTools,
    Footer
  },
  data() {
    return {
      appDownload:true
    }
  },
  methods: {
    getQinMingJieDate() {
      let date = new Date()
      let fullYear = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      //清明节的日期是不固定的，规律是：闰年开始的前2年是4月4日，闰年开始的第3年和第4年是4月5日
      if (this.isLeapYear(fullYear) || this.isLeapYear(fullYear - 1)) {
        return month === 4 && day === 4;
      } else {
        return month === 4 && day === 5;
      }
    },
    //判断是否是闰年
    isLeapYear(Year) {
      if (((Year % 4) === 0) && ((Year % 100) !== 0) || ((Year % 400) === 0)) {
        return true;
      } else {
        return false;
      }
    },
    //获取配置信息
    getChannelConfig(){
      apiChannelConfig().then(res=>{
        this.$store.commit('set_channelConfig',res.data)
      })
    }
  },
  created() {
    this.getChannelConfig()
  },
  watch: {},
  computed: {
    key() {
      return this.$route.path
    },
    style() {
      let style = ''
      let date = new Date()
      let month = date.getMonth() + 1
      let day = date.getDate()
      if (this.$route.name === 'Home') {
        if (month === 12 && day === 13) {
          //南京大屠杀
          style = 'filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);-webkit-filter: grayscale(100%)'
        } else if (this.getQinMingJieDate()) {
          //清明节
          style = 'filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);-webkit-filter: grayscale(100%)'
        }
      }
      return style
    },
  }
}
</script>

<style lang='less' scoped>
#layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F4F4F4;
  overflow: hidden;
  .appDownload{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 80px;
    background: rgba(40, 55, 72, 0.6);
    z-index: 999999;
    .btn{
      margin: 0 30px;
      position: absolute;
      right: 0;
      cursor: pointer;
    }
    .ad-conetnt{
      width: 1200px;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      .conetnt{
        height: 60px;
        object-fit: contain;
      }
      .qrcode{
        position: absolute;
        bottom: 12px;
        right: 0;
      }
    }
  }
}

</style>

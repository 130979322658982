import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';

Vue.use(VueRouter)
import Layout from '@/views/Layout/index';
import page404 from '@/views/404';

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/',
    children: [
      // 全部游戏
      {
        path: '/',
        name: 'AllGame',
        component: () => import('@/views/AllGame/index'),
        meta: {keepAlive: false}
      },

      // 游戏列表筛选
      {
        path: 'selectgame',
        name: 'SelectGame',
        component: () => import('@/views/AllGame/selectGame/index'),
        meta: {keepAlive: false}
      },
      // 账号详情
      {
        path: 'gamedetails',
        name: 'GameDetails',
        component: () => import('@/views/AllGame/gameDetails/index'),
        meta: {keepAlive: false}
      },

    ]
  },
  // 404page
  {path: '/404', name: 'page404', component: page404},
  {
    path: '*', // 页面不存在的情况下会跳到404页面
    redirect: '/',
    name: 'notFound',
    hidden: true
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('loginStatus') == 'true') { // 已经登陆

  }
  if (to.matched.some(m => m.meta.auth)) {
    // 对路由进行验证
    if (localStorage.getItem('loginStatus') == 'true') { // 已经登陆
      store.commit('set_loginStatus', localStorage.getItem('loginStatus'))
      store.commit('set_userData', JSON.parse(localStorage.getItem('userData')))
      store.commit('set_token', localStorage.getItem('token'))
      next()     // 正常跳转到你设置好的页面
    } else {
      // 未登录则跳转到登陆界面，query:{ Rurl: to.fullPath}表示把当前路由信息传递过去方便登录后跳转回来；
      next({path: '/login', query: {Rurl: to.fullPath}})
    }
  } else {
    next()
  }
})

export default router

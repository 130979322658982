export default {
  api: 'https://api.pxb7.com/',
  // api: 'https://api-demo.pxb7.com/',

  chatApi: 'https://api.pxb7.com/im/',
  // chatApi: 'https://im.pxb7.com/im/',

  socketApi: 'wss://api.pxb7.com:8080',
  // socketApi: 'wss://im.pxb7.com:8080'
}

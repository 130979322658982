<template>
  <div id='app'>
    <router-view></router-view>
    <el-backtop target="#app"/>
  </div>
</template>
<script>
import store from "@/store";

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      //白名单
      whiteList: ['/chatIndex', '/chatRoom'],
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },

    getRequestParams() {
      let url = location.href;
      let requestParams = {};
      if (url.indexOf("?") !== -1) {
        let str = url.substr(url.indexOf("?") + 1); //截取?后面的内容作为字符串
        let strs = str.split("&"); //将字符串内容以&分隔为一个数组
        for (let i = 0; i < strs.length; i++) {
          requestParams[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
          // 将数组元素中'='左边的内容作为对象的属性名，'='右边的内容作为对象对应属性的属性值
        }
      }
      return requestParams;
    },

    /**
     * 获取location里面的参数
     * @type {(function(*): (*|string|string))|*}  传要参数名 查值
     */
    getQueryString(name){
      var winObj = arguments.length > 1 ? arguments[1] : window;
      var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
      if (typeof (winObj.location) != "object") { winObj = window; }
      if (reg.test(winObj.location.href)) return unescape(RegExp.$2.replace(/\+/g, " ")); return "";
    }
  },
  mounted() {
    let search = this.getRequestParams()
    if (this.whiteList.indexOf(location.pathname) === -1 && this._isMobile()) {
      let path = 'http://47.96.229.17:8082'
      if (location.pathname === "/gamedetails") {
        location.replace(`${path}/pages/product/detail${location.search}`)
      } else if (location.pathname === "/selectgame") {
        location.replace(`${path}/pages/product/productList?gameId=${search.game_id}&productType=product&s=${this.getQueryString('s')}`)
      } else {
        location.replace(`${path}?s=${this.getQueryString('s')}`)
      }
      // this.$router.replace('/m_index');
    } else {
    }
  },
  watch: {},
  computed: {},
  created: function () {

    //处理过期token
    let tokens = localStorage.getItem('chatTokens')
    if (tokens) {
      tokens = JSON.parse(tokens)
      let newTokens = []
      let nowTime = new Date().getTime()
      tokens.forEach(item => {
        let day = (nowTime - item.time) / (1000 * 60 * 60 * 24)
        if (day <= 30) {
          newTokens.push(item)
        }
      })
      localStorage.setItem('chatTokens', JSON.stringify(newTokens))
    }
    if(store.state.selectToken == ''){
      store.commit('set_selectToken',this.getQueryString('s'))
    }

  },

}
</script>
<style lang='less' scoped>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F4F4F4;
  overflow: hidden;
}

</style>

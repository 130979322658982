<template>
  <div class="game-footer">
    <div class="footer-left">
      <router-link class="logo" :to="{path:'/',query: {s:sData}}">
        <img :src="$store.state.channelConfig.below_logo">
<!--        <img src="@/assets/image/Footer/footerLogo.png">-->
      </router-link>
    </div>
    <div class="footer-border"></div>
    <div class="footer-right">
      <div class="link-list">
        <a href="javascript:;" class="linkItem">选号网站</a>
        <a href="javascript:;" class="linkItem">账号列表</a>
        <a href="javascript:;" class="linkItem">账号详情</a>
      </div>
      <div class="copyright">&copy;版权所有 {{ $store.state.channelConfig.below_copyright }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameFooter',
  data() {
    return {
      sData: this.$route.query?.s
    }
  },
}
</script>

<style lang="less" scoped>
.game-footer{
  width: 100vw;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  .footer-left{
    width: 180px;
    height: 180px;
    img{
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .footer-border{
    width: 1px;
    height: 186px;
    display: block;
    margin: 0 138px 0 65px;
    background: radial-gradient(#000000, rgba(255,255,255,0.00));
  }
  .footer-right{
    .link-list{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .linkItem{
        display: inline-block;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #4a4a4a;
        height: 14px;
        line-height: 14px;
        &:nth-child(2n){
          margin: 0 20px;
          padding: 0 20px;
          border-left: 1px #A7A7A7 solid;
          border-right: 1px #A7A7A7 solid;
        }
      }
    }
    .copyright{
      margin-top: 19px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Light;
      font-weight: 300;
      color: #9b9b9b;
    }
  }
}
</style>

// 在http.js中引入axios
import axios from 'axios';
import QS from 'qs';
import md5 from 'js-md5'
import router from '@/router/index'
import {Message} from 'element-ui'
import evn from "../util/evn";

axios.defaults.baseURL = evn.api;
// 请求超时。。。。。。。。。。
axios.defaults.timeout = 30 * 1000;
// 请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

import store from '@/store/index';
import de from "element-ui/src/locale/lang/de";


/**
 * 获取location里面的参数
 * @type {(function(*): (*|string|string))|*}  传要参数名 查值
 */
const getQueryString = (name => {
  var winObj = arguments.length > 1 ? arguments[1] : window;
  var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
  if (typeof (winObj.location) != "object") { winObj = window; }
  if (reg.test(winObj.location.href)) return unescape(RegExp.$2.replace(/\+/g, " ")); return "";
})

// 请求拦截器
axios.interceptors.request.use(
  config => {
    config.headers.Authorization = getQueryString('s')
    let num = "";
    for (let i = 0; i < Math.floor(Math.random() * 10) + 1; i++) {
      num += Math.floor(Math.random() * 10);
    }
    config.headers.token =  md5(num);
    config.headers.ApiToken = md5(new Date().getFullYear() + '' + (new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : new Date().getMonth() + 1) + 'PXB7' + md5(num));
    return config;
  },
  error => {
    return Promise.error(error);
  })


// 响应拦截器
axios.interceptors.response.use(
  response => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.data.code === 200) {
      return Promise.resolve(response);
    } else {
      if (response.data.code === 10001) {
        Message({
          type: "error",
          message: response.data.msg,
          center: true
        })
      }
      // apiLogout()
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  error => {
    if (error.data.code) {
      return Promise.reject(error.response);
    }
  }
)


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */

export function get(url, params) {

  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}


/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {

  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}


export function getURLPara(name) {
  var reg = new RegExp("[^\?&]?" + encodeURI(name) + "=[^&]+");
  var arr = this.search.match(reg);
  if (arr != null) {
    console.log(decodeURI(arr[0].substring(arr[0].search("=") + 1)));
    return decodeURI(arr[0].substring(arr[0].search("=") + 1));
  }
  return "";
}
